body, html {
  height: 100%;
}


.landing-header-intro {
  font-size: 2vw;
  color: "#E8F3FF";
  letter-spacing: 5px;
  margin-top: 5px;
  white-space: nowrap;
}

.landing-header {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 6vw;
}

.action-row {
  display: flex;
  flex-direction: row;
}

.action-link {
  align-items: center;
  justify-content: center;
  font-size: 1vw;
  min-width: 100px;
  max-width: 100px;
  margin-right: 20px;
  background-color: #165DFF;
  color: #f7f8fa;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.action-link:hover {
  background-color: #6AA1FF;
  transition: background-color 0.3s ease;
}

.action-link:active {
  background-color: #072CA6;
  transition: background-color 0.1s ease;
}

.description {
  font-size: 1vw;
}

.outline-cta {
  min-width: 100px;
  max-width: 100px;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  background-color: transparent;
  border-width: 0.5px;
  border: solid;
  border-color: #165DFF;
}

.outline-cta:hover {
  background-color: #6AA1FF;
  transition: background-color 0.3s ease;
  color: #f7f8fa;
}

.outline-cta:active {
  background-color: #072CA6;
  transition: background-color 0.1s ease;
  color: #f7f8fa;
}
